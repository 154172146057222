/**
 * 集合に関するユーティリティ群
 */
export const SetUtils = {
    /**
     * ふたつの集合の要素が等しいかどうか判定します。
     *
     * @param xs 比較対象 1
     * @param ys 比較対象 2
     * @returns ふたつの集合が等しければ `true`、それ以外は `false`
     */
    equals(xs: ReadonlySet<unknown>, ys: ReadonlySet<unknown>): boolean {
        if (xs.size !== ys.size) {
            return false;
        }

        for (const x of xs) {
            if (!ys.has(x)) {
                return false;
            }
        }

        return true;
    },

    /**
     * ある集合が別の集合を含むか判定します。
     *
     * @param xs 含む集合
     * @param ys 含まれる集合
     * @returns `xs` が `ys` の全要素を含んでいれば `true`、それ以外は `false`
     */
    contains(xs: ReadonlySet<unknown>, ys: ReadonlySet<unknown>): boolean {
        if (xs.size < ys.size) {
            return false;
        }

        for (const y of ys) {
            if (!xs.has(y)) {
                return false;
            }
        }

        return true;
    },
};
