import { ReactNode } from "react";
import { Node } from "../../../../model/database";
import { TreeNode } from "./tree-node";

export namespace constructTreeNodes {
    /**
     * {@link constructTreeNodes} 関数のパラメーター
     */
    export interface Parameters {
        /**
         * 展開状態のノード ID 集合
         *
         * @default 空集合
         */
        readonly expandedNodeIds?: ReadonlySet<Node["id"]> | undefined;
        /**
         * ツリーに含める全ノード
         *
         * キーは `id` で、値が該当するノードです。
         */
        readonly nodes: ReadonlyMap<Node["id"], Node>;
        /**
         * 位置の上書き情報
         *
         * ツリー要素を移動直後、クラウド上のデータを更新するまでの間に表示位置
         * を変えるために利用します。
         */
        readonly overrideMap?:
            | ReadonlyMap<Node["id"], OverrideInfo>
            | undefined;
        /**
         * 各ノードの描画内容を作成する関数
         *
         * @default ノード名を表示する関数
         */
        readonly renderTreeNode?:
            | ((node: Node, expanded: boolean) => ReactNode)
            | undefined;
    }

    /**
     * 上書き情報
     */
    export interface OverrideInfo {
        /**
         * 上書きする `parentId` 値
         */
        readonly parentId: Node["parentId"];
        /**
         * 上書きする `treeIndex` 値
         */
        readonly treeIndex: NonNullable<Node["treeIndex"]>;
        /**
         * 上書き情報の作成元ノードの `updatedAt` 値
         * この値がノードより古い場合、上書きを中止します。
         */
        readonly updatedAt: Node["updatedAt"];
    }
}

/**
 * ノード リストからツリー構造を作成します。
 *
 * 戻り値はふたつあります。
 *
 * - `topTreeNodes` ........ トップ ノードの配列。トップ ノードとは `parentId`
 *                           が `"null"` であるノード、または `parentId` に該当
 *                           するノードが存在しなかったノードです。
 * - `expandableNodeIds` ... 展開可能な (≓ 子を持つ) ノードの ID 集合。
 *
 * `expandableNodeIds` は、すべて展開する操作や、全て展開済みか判定する際に利用
 * すると便利です。
 *
 * @param parameters パラメーター
 * @returns 構築したツリー要素と、展開可能なノードの ID 集合
 */
export function constructTreeNodes(
    parameters: constructTreeNodes.Parameters,
): [topTreeNodes: TreeNode[], expandableNodeIds: ReadonlySet<Node["id"]>] {
    const { expandedNodeIds, nodes, overrideMap, renderTreeNode } = parameters;

    const topNodes: Node[] = [];
    const nodesGroupedByParentId = new Map<Node["parentId"], Node[]>();
    for (const node of nodes.values()) {
        const parentId = getParentId(node, overrideMap);

        // トップ ノードを収集する
        if (parentId === "null" || !nodes.has(parentId)) {
            topNodes.push(node);
        }

        // ノードを `parentId` でグループ化する
        let group = nodesGroupedByParentId.get(parentId);
        if (group === undefined) {
            group = [];
            nodesGroupedByParentId.set(parentId, group);
        }
        group.push(node);
    }

    // ツリー構造を構築する
    const context: Context = {
        expandableNodeIds: new Set<Node["id"]>(),
        expandedNodeIds,
        nodesGroupedByParentId,
        overrideMap,
        renderTreeNode,
    };
    const topTreeNodes = topNodes
        .sort((a, b) => compareTreeIndex(a, b, overrideMap))
        .map((node) => createTreeNode(node, context));

    return [topTreeNodes, context.expandableNodeIds];
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

interface Context {
    readonly expandableNodeIds: Set<Node["id"]>;
    readonly expandedNodeIds: ReadonlySet<Node["id"]> | undefined;
    readonly nodesGroupedByParentId: ReadonlyMap<Node["parentId"], Node[]>;
    readonly overrideMap:
        | ReadonlyMap<Node["id"], constructTreeNodes.OverrideInfo>
        | undefined;
    readonly renderTreeNode:
        | ((node: Node, expanded: boolean) => ReactNode)
        | undefined;
}

/**
 * ノードからツリー要素を作成します。
 *
 * @param node ノード
 * @param parameters パラメーター
 * @returns 作成したツリー要素
 */
function createTreeNode(node: Node, context: Context): TreeNode {
    const { id, name } = node;
    const {
        expandableNodeIds,
        expandedNodeIds,
        nodesGroupedByParentId,
        overrideMap,
        renderTreeNode,
    } = context;
    const children = nodesGroupedByParentId
        .get(id)
        ?.sort((a, b) => compareTreeIndex(a, b, overrideMap))
        .map((childNode) => createTreeNode(childNode, context));

    // 子がなければ末端 (展開不可)
    if (children === undefined) {
        const title = renderTreeNode?.(node, false) ?? node.name;
        return new TreeNode(id, name, title);
    }

    // 子持ち (展開可能)
    expandableNodeIds.add(node.id);
    const expanded = expandedNodeIds?.has(node.id) ?? false;
    const title = renderTreeNode?.(node, expanded) ?? node.name;
    return new TreeNode(id, name, title, { children, expanded });
}

/**
 * 二つのノードを、ツリー インデックス順に並ぶように比較します。
 *
 * ツリー インデックスが無いノードがあった場合、後ろに作成日時順に並べます。
 *
 * @param nodeA 比較対象 1
 * @param nodeB 比較対象 2
 * @param overrideMap 上書き情報
 * @returns `a` の方が小さければ負数、大きければ正数、等しければ `0`
 */
function compareTreeIndex(
    nodeA: Node,
    nodeB: Node,
    overrideMap:
        | ReadonlyMap<Node["id"], constructTreeNodes.OverrideInfo>
        | undefined,
): NonNullable<Node["treeIndex"]> {
    const a = getTreeIndex(nodeA, overrideMap);
    const b = getTreeIndex(nodeB, overrideMap);

    if (a === undefined) {
        if (b === undefined) {
            return Date.parse(nodeA.createdAt) - Date.parse(nodeB.createdAt);
        }
        return 1;
    }
    if (b === undefined) {
        return -1;
    }

    return a - b;
}

/**
 * ノードから親ノード ID とツリー インデックスを取得します。
 *
 * @param node ノード
 * @param overrideMap 上書き情報
 * @returns 親ノード ID とインデックス
 */
function getParentId(
    node: Node,
    overrideMap:
        | ReadonlyMap<Node["id"], constructTreeNodes.OverrideInfo>
        | undefined,
): Node["parentId"] {
    const override = overrideMap?.get(node.id);
    return override !== undefined && override.updatedAt >= node.updatedAt
        ? override.parentId
        : node.parentId;
}

/**
 * ノードから親ノード ID とツリー インデックスを取得します。
 *
 * @param node ノード
 * @param overrideMap 上書き情報
 * @returns 親ノード ID とインデックス
 */
function getTreeIndex(
    node: Node,
    overrideMap:
        | ReadonlyMap<Node["id"], constructTreeNodes.OverrideInfo>
        | undefined,
): NonNullable<Node["treeIndex"]> | undefined {
    const override = overrideMap?.get(node.id);
    return override !== undefined && override.updatedAt >= node.updatedAt
        ? override.treeIndex
        : node.treeIndex ?? undefined;
}
