import { ReactNode } from "react";
import { Node } from "../../../../API";

export namespace TreeNode {
    export interface ConstructorOptions {
        readonly children: TreeNode[];
        readonly expanded: boolean;
    }
}

/**
 * ツリー構造を表現する型
 */
export class TreeNode {
    /** 子要素 */
    readonly children: TreeNode[];
    /** 展開状態 */
    readonly expanded: boolean;
    /** 識別子 */
    readonly id: Node["id"];
    /** 名前 (検索に使われます) */
    readonly name: string;
    /** 描画内容 */
    readonly title: ReactNode;

    /**
     * 新しい {@link TreeNode} インスタンスを初期化します。
     *
     * @param id 識別子
     * @param name 名前
     * @param title 描画内容
     * @param options オプション
     */
    constructor(
        id: Node["id"],
        name: string,
        title: ReactNode,
        options?: TreeNode.ConstructorOptions | undefined,
    ) {
        this.children = options?.children ?? [];
        this.expanded = options?.expanded ?? false;
        this.id = id;
        this.name = name;
        this.title = title;
    }
}
